/* eslint-disable no-await-in-loop */
import axios from 'axios';

export const getListUrlRewritesByTargetPaths = async (grn) => {
  const geminiData = await axios({
    method: 'post',
    url: process.env.GEMINI_URL_MANAGER_ENDPOINT,
    data: {
      targetPaths: [grn],
      tenantId: process.env.TENANT_ID,
    },
    headers: {
      Authorization: `Bearer ${process.env.GEMINI_RESOURCE_JWT}`,
    },
  });

  let itUrl = '';
  let enUrl = '';

  if (geminiData?.data?.urlRewrites) {
    // eslint-disable-next-line no-restricted-syntax
    for (const urlRewritesData of geminiData.data.urlRewrites) {
      if (urlRewritesData.context === undefined) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (urlRewritesData.context === 'it_IT') {
        itUrl = urlRewritesData?.requestPath ?? '';
      }
      if (urlRewritesData.context === 'en_US') {
        enUrl = urlRewritesData?.requestPath ?? '';
      }
    }
  }

  return {
    itUrl,
    enUrl,
  };
};

export default {
  path: '/api/getgeminidata',
  async handler(req, res) {
    try {
      const urlData = await getListUrlRewritesByTargetPaths(req.query.grn);
      res.send(urlData);
    } catch (e) {
      res.send(e);
    }
  },
};
